<template>
  <div class="input_calendar mid">
    <input
      type="text"
      :placeholder="$t('comp.010')"
      v-model="formattedDate"
      ref="dp"
      class="datepicker"
      :id="id"
      :readonly="readonly"
      :disabled="isDisabled"
      autocomplete="off"
    />
    <button class="tbl_icon calendar" @mousedown.stop.prevent @click.stop.prevent="togglePicker">{{ $t('comp.014') }}</button>
  </div>
</template>

<script>
export default {
  name: 'DatePicker',

  props: {
    value: { type: String, default: '', required: true },
    readonly: { type: Boolean, default: false, required: false },
    isError: { type: Boolean, default: false, required: false },
    disabled: { type: Boolean, default: false, required: false },
    id: { type: String, default: '', required: false },
    dateRange: {
      type: Object,
      default: () => {
        return {
          min: '',
          max: ''
        }
      },
      required: false
    }
  },

  data () {
    return {
      isDisabled: false,
      dpMountYn: 'N',
      unformattedDate: this.value,
      formattedDate: this.value.replace(/(\d{4})(\d{2})(\d{2})/, '$1.$2.$3'),
      show: false
    }
  },

  watch: {
    isError () {
      if (this.isError) {
        // this.$ekmtcCommon.showErrorTooltip(document.querySelector('#' + this.id), '입금일을 입력해주세요.')
        this.$ekmtcCommon.showErrorTooltip(document.querySelector('#' + this.id), this.$t('msg.CSBL220.022'))
      } else {
        this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#' + this.id))
      }
    },
    disabled (n, o) {
      this.isDisabled = n
      if (this.disabled) {
        this.hidePicker()
      }
    },
    dateRange: {
      deep: true,
      handler () {
        this.dpDestroy()
        this.dpMount()
      }
    },
    value () {
      if (this.value.replace(/(\d{4})(\d{2})(\d{2})/, '$1.$2.$3') !== this.unformattedDate) {
        this.unformattedDate = this.value.replace(/(\d{4})(\d{2})(\d{2})/, '$1.$2.$3')
      }
      this.formattedDate = this.value.replace(/(\d{4})(\d{2})(\d{2})/, '$1.$2.$3')
    },
    formattedDate () {
      this.$emit('input', this.formattedDate.replaceAll('.', ''))
    },
    readonly () {
      if (!this.readonly) {
        this.dpMount()
      } else {
        this.dpDestroy()
      }
    }
  },

  mounted () {
    if (!this.readonly) {
      this.dpMount()
    } else {
      this.dpDestroy()
    }

    // $(this.$refs.dp).daterangepicker(
    //   {
    //     singleDatePicker: true,
    //     showDropdowns: true,
    //     autoApply: true,
    //     locale: {
    //       format: 'YYYY.MM.DD'
    //     }
    //   },
    //   (s, e, l) => {
    //     this.unformattedDate = s.format('YYYYMMDD')
    //     this.$emit('input', this.unformattedDate)
    //   }
    // )
  },
  methods: {
    dpMount () {
      if (this.dpMountYn === 'Y') {
        return
      }

      $(this.$refs.dp).datepicker({
        dateFormat: 'yy.mm.dd',
        closeText: '닫기',
        prevText: '이전달',
        nextText: '다음달',
        currentText: '오늘',
        monthNames: [`1${this.$t('msg.CSBK100.322')}`,
        `2${this.$t('msg.CSBK100.322')}`,
        `3${this.$t('msg.CSBK100.322')}`,
        `4${this.$t('msg.CSBK100.322')}`,
        `5${this.$t('msg.CSBK100.322')}`,
        `6${this.$t('msg.CSBK100.322')}`,
        `7${this.$t('msg.CSBK100.322')}`,
        `8${this.$t('msg.CSBK100.322')}`,
        `9${this.$t('msg.CSBK100.322')}`,
        `10${this.$t('msg.CSBK100.322')}`,
        `11${this.$t('msg.CSBK100.322')}`,
        `12${this.$t('msg.CSBK100.322')}`],
        monthNamesShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        dayNames: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
        dayNamesShort: ['일', '월', '화', '수', '목', '금', '토'],
        dayNamesMin: [this.$t('comp.002'),
        this.$t('comp.003'),
        this.$t('comp.004'),
        this.$t('comp.005'),
        this.$t('comp.006'),
        this.$t('comp.007'),
        this.$t('comp.008')],
        weekHeader: '주',
        minDate: this.dateRange.min,
        maxDate: this.dateRange.max,
        onSelect: (v) => {
          this.unformattedDate = v.replaceAll('.', '')
          this.$emit('input', this.unformattedDate)
        },
        onClose: () => {
          this.show = false
        },
        beforeShow: () => {
          this.show = true
        }
      })

      this.dpMountYn = 'Y'
    },
    dpDestroy () {
      if (this.dpMountYn !== 'Y') {
        return
      }

      $(this.$refs.dp).datepicker('destroy')
      this.dpMountYn = 'N'
    },
    showPicker (e) {
      $(this.$refs.dp).datepicker('show')
      this.show = true
    },

    hidePicker (e) {
      $(this.$refs.dp).datepicker('hide')
      this.show = false
    },
    togglePicker (e) {
      if (!this.disabled) {
        if (this.show) {
          this.hidePicker()
        } else {
          this.showPicker()
        }
      }
    }
  }
}
</script>

<style>
.ui-datepicker .ui-state-default {
  border: 1px solid #ffffff !important;
  background: #ffffff !important;
  font-weight: normal;
  color: #454545;
  text-align: center !important;
}

.ui-datepicker .ui-state-active {
  background: #007fff !important;
}

.ui-datepicker .ui-datepicker-title {
    margin: 0 2.3em;
    line-height: 1.8em;
    text-align: center;
    font-size: 12.6px;
}
</style>
