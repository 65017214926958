var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input_calendar mid" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.formattedDate,
          expression: "formattedDate",
        },
      ],
      ref: "dp",
      staticClass: "datepicker",
      attrs: {
        type: "text",
        placeholder: _vm.$t("comp.010"),
        id: _vm.id,
        readonly: _vm.readonly,
        disabled: _vm.isDisabled,
        autocomplete: "off",
      },
      domProps: { value: _vm.formattedDate },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.formattedDate = $event.target.value
        },
      },
    }),
    _c(
      "button",
      {
        staticClass: "tbl_icon calendar",
        on: {
          mousedown: function ($event) {
            $event.stopPropagation()
            $event.preventDefault()
          },
          click: function ($event) {
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.togglePicker.apply(null, arguments)
          },
        },
      },
      [_vm._v(_vm._s(_vm.$t("comp.014")))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }